.navcontainer {
    width: 100%;
    height: 65px;
    background: rgb(64,64,64);
background: linear-gradient(180deg, rgba(64,64,64,1) 0%, rgba(25,25,25,1) 100%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    position: fixed;
    z-index: 99;
}
.menu-mobile {
    display: none;
}
.active-link {
    color:red;
}
.discord-icon {
    position: relative;
    top: 5px;
    right: 2px;
    width: 1.2rem;
    filter: invert(1);
}
.navbuttons a:hover {
    cursor: pointer;
}
.navbuttons a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    transition: opacity 0.3s;

}
.navcontainer a:hover {
    border-bottom: 3px solid #ff5323;
    border-collapse: collapse;
}

.navbuttons {
    margin-right: 30px;
    margin-bottom: 0.4rem;
   
}

.button-nav {
    margin-right: 2rem;
    font-size: 0.8rem;
}

.logo-img {
    width: 5rem;
    margin-right: 1rem;
}
.logo-img:hover {
    cursor: ponter;
}
.only-mobile {
    display: none;
}
.menu {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    color: white;
    transition: color 0.5s;
}

@media (max-width: 800px) {
    .navbuttons {
        display: none;
    }
    .menu-mobile {
        display: flex;
    }
    .navcontainer {
        justify-content: space-between;
    }
}

    