.footer-background {
    width: 100%;
    height: auto;
    color: white;
    padding-top: 5rem;
    padding-bottom: 0.5rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/img/development-bg.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: darken;
}

.social-buttons-container {
    display: flex;
    justify-content: center;
    margin-right: 3.3rem;
    align-items: center;
}

.social-buttons-container .discord-icon {
    width: 2rem;
}

.social-buttons-container .discord-icon:hover {
    cursor: pointer;
    filter: invert(87%) sepia(40%) saturate(3702%) hue-rotate(324deg) brightness(107%) contrast(103%);
}
.social-buttons-container a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    transition: opacity 0.3s;

}
.button-social-footer {
    margin-right: 15px;
    color: white;
}
.button-social-footer:nth-child(2) {
    margin-top: 9px;
}
.footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-text {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.footer-text-container {
    margin-bottom: 1rem;
}
.footer-text:first-of-type {
    padding-top: 55px;
}

.footer-background a:hover {
    cursor: pointer;
    color: rgb(255, 174, 76);
}

.footer-img-cont {}

.footer-img {
    width: 160px;
}

.footer-links {
    opacity: 0.7;
    display: flex;
    flex-direction: column;
}

.button-nav-footer {
    margin-bottom: 15px;
    color: white;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    text-underline-offset: 0;
}

@media (max-width: 860px) {
    .footer {
        flex-direction: column;
    }
    .footer-background {
        height: 100%;
    }
    .social-buttons-container {
        margin-top: 20px;
        margin-left: 3.3rem;
    }
    .footer-links {
        margin-top: 25px;
        flex-direction: row;

    }
    .button-nav-footer {
        margin-right: 25px;
    }
}