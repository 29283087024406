.custom-container {
    padding-top: 40px;
    display: flex;
    flex: 1 1 100%;
    height: 65vh;
    background: url('../../assets/img/custom-bg.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-color: #656565;
    color: white;
    justify-content: center;
}

.develop-desc {
    font-family: 'Poppins', sans-serif;
}
.title {
    font-family: 'Permanent Marker', cursive;
}

.divp {
    width: 45vh;
    display: flex;
    height: auto;
    z-index: 0;
}

@media (max-width: 860px) {

    .custom-container {
        padding: 0px;
        justify-content:space-between;
        height: 100%;
    }
    .texto {
        
        width: 100%;
        
    }
    .divp {
        display: none;
    }

}