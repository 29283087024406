.greetingcontainer {
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    flex-direction: row;
    background-color: #656565;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url('../../assets/img/background.jpg');
}

.char-container {
    width: 40%;
    margin-top: auto;
}
.characters {
    width: 92%;
    max-width: 100%;
    height: auto;
    z-index: 2;
    filter: brightness(1) !important;
}
.cover-effect-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height:  100%;
}
.greeting {     
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100vh;
    line-height: 0;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    padding-top: 2rem;
    color: white;
}
.logo-image {
    width: 45%;
    margin-top: 20%;

}
.text-bg-sc {
    color:#fff123;
}
.text-bg {
    font-family: 'Glory', sans-serif;
    text-align: center; 
    margin: 1rem 0 0rem 0;
    font-size: 2rem;
    line-height: 2rem;
    padding: 1rem;
    background: rgb(255,79,30);
    text-shadow:
    -1px -1px 0 #000000a1,
     0   -1px 0 #000000a1,
     1px -1px 0 #000000a1,
     1px  0   0 #000000a1,
     1px  1px 0 #000000a1,
     0    1px 0 #000000a1,
    -1px  1px 0 #000000a1,
    -1px  0   0 #000000a1;
background: linear-gradient(0deg, rgba(255,79,30,1) -50%, rgba(255, 79, 30, 0.308) 100%);
    border-radius: 45px;
    -webkit-box-shadow: 10px 10px 61px -6px rgba(0,0,0,0.38);
-moz-box-shadow: 10px 10px 61px -6px rgba(0,0,0,0.38);
box-shadow: 10px 10px 61px -6px rgba(0,0,0,0.38);
}
.button-cover {
    font-size: 2.4rem;
    background-color: #e28140;
    padding: 0.9rem 2rem 1.5rem 2rem;
    border-radius: 40px;
    color: white;
    transition: all 0.2s;
    margin-top: 2rem;
}
.button-cover:hover {
    background-color: #e28140;
    scale: 1.1;
}
@media (max-width: 690px) {
    .char-container {
        display: none;
    }
    .logo-image {
        width: 60%;
    }
    .greeting {
        width: 80% ;
    }
    .cover-effect-container {
        width: 100%;
    }
}

@media (min-width: 791px){
    .greeting {
        align-self: flex-end;
        padding-right: 3rem;
    }
    .char-container {
        display: block;
    }
}
