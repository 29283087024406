.playcontainer {
    width: 100%;
    height: auto;
    display: flex;
    padding-top: 12rem;
    padding-bottom: 12rem;;
    background: url('../../assets/img/play-bg.jpg');
    background-size: cover;
    justify-content: space-evenly;
    background-attachment: fixed;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    flex-wrap: wrap;
    background-size: cover;
    background-color: rgb(58, 58, 58);
}
.play-card {
    padding-top: 4rem;
}
@media (max-width: 1090px) {
    .playcontainer {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}