.shopcontainer {
    height: 100vh;
    max-height: 100%;
    width: 100%;    
    background: url('../../assets/img/background.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}


.title-container {
    text-align: center;
}

.shop-box-container {
    height: auto;
    max-width: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    background-color: #2f2f2f;
    border-radius: 13px;
    padding: 2rem 5rem 3rem 5rem;
    margin-top: 3rem;
    align-self: center;
    justify-self: center;
    scale: 0.85;
    box-shadow: 9px 8px 15px -4px rgba(0,0,0,0.3);
-webkit-box-shadow: 9px 8px 15px -4px rgba(0,0,0,0.3);
-moz-box-shadow: 9px 8px 15px -4px rgba(0,0,0,0.3);
}
.shop-cards-container {
    margin-top: 2rem;
    display: flex;
}