.cardcontainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 360px;
    height: 360px;
    border: 2px solid rgba(46, 46, 46, 0.382);
    transition: all 1s;
    background-color: rgba(56, 56, 56, 0.884);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.cardcontainer:hover {
    transform:rotate(45deg);
    cursor: pointer;
    background-color: rgba(66, 66, 66, 0.884);
}

.cardtitle {
    font-size: 4rem;
    display: flex;
    position: relative;
    top: -80px;
    color: #ffffff;
    font-family: 'Permanent Marker', cursive;
}
.image {
    align-self: center;
    margin-top: 40px;
    max-width: 200px;
}
.description {
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: block;
    text-align: center;
    position: absolute;
    top: 20px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}
.card-footer-text {
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: block;
    text-align: center;
    position: absolute;
    bottom: 90px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}
.btn {
    font-size: 1.4rem;
    border-radius: 50px;
    padding: 1rem 3rem 1rem 3rem;
    width: auto;
    border: 0;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    transition: scale 0.5s;
    background-color: #e9a041;
    border: 1px solid rgb(64, 64, 64);
}
.div-btn {
    display: flex;
    margin-top: auto;
    margin-bottom: 20px;
    justify-content: center;
    
}
.btn:hover {
    cursor: pointer;
    scale: 1.05;
}
@media (max-width: 1090px) {
    .cardcontainer {
        margin-top: 7rem;
    }
}
@media (max-width: 1400px) {
    .cardcontainer {
        scale: 0.85;
    }
}