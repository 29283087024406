.development {
    width: 100%;
    display: flex;
    height: 65vh;
    background: url('../../assets/img/development-bg.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-color: #656565;
    color: white;
    justify-content: center;
}

.develop-btn {

    font-size: 1.4rem;
    margin-top: 1rem;
    border-radius: 50px;
    padding: 0.3rem 1rem 0.3rem 1rem;
    width: 40%;
    align-self: flex-end;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    transition: scale 0.5s;
    background-color: #e9a041;
    border: 3px solid rgb(76, 76, 76);
}
.develop-desc {
    font-family: 'Poppins', sans-serif;
}
.title {
    font-family: 'Permanent Marker', cursive;
}
.divp {
    width: 45vh;
    display: flex;
    z-index: 0;
}
.develop-btn:hover {
    cursor: pointer;
    scale: 1.05;
}

.develop-btn {
    align-self: center;
    width: 100%;
}
@media (min-width: 861px) {
    .texto {
        display: flex;
        flex-direction: column;
        width: 40%;
        padding: 5rem;
    }
    .development {
        padding-top: 3rem;
    }
}
@media (max-width: 860px) {
    .divp {
        display: none;
    }
    .texto {
        
        width: 100%;
        padding: 3rem;
        text-align: center
    }
    .development {
        justify-content: center;
        height: 100%;
    }
}